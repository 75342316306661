import { withRouter, Link } from 'react-router-dom';
import React from 'react';
import { Layout, Breadcrumb, Card, Col, Row } from 'antd';
import Catalog from '../shared/lib/catalog';
import constants from '../shared/constants';

const { Content } = Layout;
const { Meta } = Card;

const Products = withRouter(({ history }) => {
  const products = Catalog.get();

  return (
    <Content style={{ padding: '0 50px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
      <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Products</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={16}>
        {products &&
          products.map((product) => (
            <Col span={8} key={product.handle}>
              <Card
                hoverable
                style={{ width: 240 }}
                cover={<img alt={product.title} src={product.images && product.images.length > 0 ? product.images[0].src : constants.merchant.missingImageUrl}
                  onClick={() => { history.push(`/product/${product.handle}`) }}
                />}
              >
                <Meta title={product.title}
                  description={product.product_type}
                />
                <strong>$ {product.variants[0].price}</strong>
              </Card>
            </Col>
          ))
        }
      </Row>
    </Content>
  );
})

export default Products;
