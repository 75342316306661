import React, { useState } from 'react';
import { Form, Icon, Input, Button, Layout, Breadcrumb, Result } from 'antd';
import { withRouter, Link } from 'react-router-dom';

import AuthenticationService from '../services/tbank/authentication';
import constants from '../shared/constants';

const { Content } = Layout;

const Login = withRouter(({ history }) => {
  const [status, setStatus] = useState();
  const [message, setMessage] = useState();
  const [userID, setUserID] = useState('');
  const [PIN, setPIN] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    AuthenticationService
      .loginAndSave(userID, PIN)
      .then((response) => {
        console.log(response);
        const { CustomerID, BankID } = response.ServiceResponse['Login_OTP_Authenticate-Response'];
        const resultString = `Welcome, Customer ${CustomerID} from Bank ${BankID}`;
        setStatus('success');
        setMessage(resultString);
        history.push('/');
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        const [globalErrorID, errorText, errorDetails, ..._errorOther] = error.split(';');
        setStatus('error');
        if (typeof constants.api.globalErrors[globalErrorID] === 'undefined') {
          setMessage(errorText || errorDetails);
        } else {
          setMessage(constants.api.globalErrors[globalErrorID]);
        }
      });
  }

  return (
    <Content style={{ padding: '0 50px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Login</Breadcrumb.Item>
      </Breadcrumb>
      {!status &&
        <Form onSubmit={handleSubmit} className="login-form">
          <Form.Item>
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="UserID"
              onChange={e => setUserID(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="PIN"
              onChange={e => setPIN(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" block>
              Log in
            </Button>
          </Form.Item>
        </Form>
      }
      {status && <Result status={status} title={message} />}
    </Content>
  );
});

export default Login;
