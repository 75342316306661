import axios from 'axios';
import constants from '../constants';

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: constants.merchant.productsUrl,
  timeout: constants.api.timeout,
  headers: {
    'Accept': 'application/json'
  },
  method: 'GET'
});

/**
 * Request Wrapper with default success/error actions
 */
const request = function (endpoint) {
  const onSuccess = function (response) {
    console.log(response);
    return response.data;
  }

  const onError = function (error) {
    // Forward on the error if there is just a string
    if (typeof error == 'string') {
      return Promise.reject(error);
    }
    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);

    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  }

  return client(endpoint)
    .then(onSuccess)
    .catch(onError);
}

export { request as default };
