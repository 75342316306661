const prepare = (catalog) => {
  let mapping = {};
  catalog.forEach((product, index) => {
    mapping[product.handle] = index;
  });
  window.localStorage.setItem('catalogMap', JSON.stringify(mapping));
};

const set = (catalog) => {
  window.localStorage.setItem('catalog', JSON.stringify(catalog));
  prepare(catalog);
};

const get = () => JSON.parse(window.localStorage.getItem('catalog') || '[]');

const clear = () => {
    window.localStorage.removeItem('catalog');
    window.localStorage.removeItem('catalogMap');
}

const getMapping = () => JSON.parse(window.localStorage.getItem('catalogMap') || '[]');

const findIndexByHandle = (handle) => getMapping()[handle];

const Catalog = {
  set, get, clear, prepare, getMapping, findIndexByHandle
};

export default Catalog;
