const initialise = () => {
  window.localStorage.setItem('cart', '[]');
};

const get = () => JSON.parse(window.localStorage.getItem('cart') || '[]');

const set = (cart) => window.localStorage.setItem('cart', JSON.stringify(cart));

const add = (handle) => {
  let cart = get();
  cart.push(handle);
  set(cart);
};

const clear = () => window.localStorage.removeItem('cart');

const Cart = {
  initialise, get, set, add, clear
};

export default Cart;
