import React, { useState, useEffect } from 'react';
import { Layout, Breadcrumb, Table, Button, Collapse, PageHeader, Form, Input, Icon, Result, Popconfirm, notification } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import Catalog from '../shared/lib/catalog';
import CartService from '../shared/lib/cart';
import CustomerStorage from '../shared/lib/customer';
import PaymentService from '../services/tbank/payment';
import NotificationService from '../services/tbank/notification';
import constants from '../shared/constants';
import ShopifyCartService from '../services/banyantree/shopifycart';

const loadingIcon = <Icon type="loading" />

const { Content } = Layout;
const { Panel } = Collapse;

const paymentMethodNames = [
  'Select a Payment Method',
  'Pay with Credit Transfer',
  'Take a Personal Loan',
  'Go to Merchant Store'
];

const sent = (type, title) => {
  notification[type]({
    message: 'Notification Sent',
    description: title
  });
};

const Checkout = withRouter(({ history }) => {

  const [dataSource, setDataSource] = useState([]);
  const [shopifyCartItems, setShopifyCartItems] = useState([]);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(1);
  const [status, setStatus] = useState('empty');
  const [message, setMessage] = useState('Performing Transaction, Please Wait...');
  const [numberOfMonths, setNumberOfMonths] = useState('12');

  // State variables used for direct transfer
  const [narrative, setNarrative] = useState(constants.merchant.name);
  const [accountFrom, setAccountFrom] = useState('Loading...');

  useEffect(() => {
    // Fill up cart
    const productMapping = Catalog.getMapping();
    const catalog = Catalog.get();
    const cartContents = CartService.get();
    console.log('cart', cartContents);

    if (cartContents.length < 1) {
      setStatus('error');
      setMessage(<b>Your cart is empty - please <Link to="/cart">add more items</Link> to your cart!</b>);
    } else {
      setDataSource(cartContents.map((handle, index) => {
        const product = catalog[productMapping[handle]];

        return {
          key: index,
          productType: product.product_type,
          product: product.title,
          ImageURL: product.images && product.images.length > 0 ? product.images[0].src : constants.merchant.missingImageUrl,
          quantity: 1,
          price: product.variants[0].price
        }
      }));

      setShopifyCartItems(cartContents.map((handle) => ({
        quantity: 1,
        id: catalog[productMapping[handle]].id
      })));

      // Fill deposit account
      setAccountFrom(CustomerStorage.getDepositAccounts()[0].accountID);
    }
  }, []);

  const subtotal = dataSource.reduce((total, product) => (total + parseFloat(product.price)), 0);
  const isLoanEnabled = subtotal >= constants.merchant.minimumLoanAmount;

  const columns = [
    {
      title: 'Product Type',
      dataIndex: 'productType',
      key: 'productType',
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: "",
      dataIndex: "ImageURL",  // this is the value that is parsed from the DB / server side
      render: theImageURL => <img alt={theImageURL} src={theImageURL} height="150" width="150" />
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
  ];

  const handleBackToCart = (event) => {
    event.preventDefault();
    history.push('/cart');
  };

  const handlePaymentChange = (key) => {
    setCurrentPaymentMethod(key ? parseInt(key, 10) : 0);
  };

  const handlePaymentNextStep = (event) => {
    event.preventDefault();
    setStatus('loading');
    if (currentPaymentMethod === 1) {
      handleCreditTransfer();
    } else if (currentPaymentMethod === 2) {
      calculateLoan();
    } else if (currentPaymentMethod === 3) {
      handleMerchantSiteTransfer();
    }
  };

  const checkoutSuccess = (message) => {
    CartService.clear();
    const customer = CustomerStorage.get();
    const phoneNumber = customer.cellphone ? customer.cellphone.phoneNumber : false;
    const emailAddress = customer.profile ? customer.profile.email : false;
    if (phoneNumber) {
      NotificationService.sendSMS(phoneNumber, `tBank X ${constants.merchant.name}\n\n${message}`)
        .then((response) => {
          console.log('SMS', response);
          sent('success', `Order summary sent to your number ending in ${phoneNumber.substr(phoneNumber.length - 4)}!`);
        });
    }

    if (emailAddress) {
      NotificationService.sendEmail(emailAddress, `tBank X ${constants.merchant.name}: Order Details`, message)
        .then((response) => {
          console.log('Email', response);
          sent('success', `Order information sent to ${emailAddress}`);
        });
    }
  };

  const handleMerchantSiteTransfer = () => {
    setStatus('loading');
    setMessage('Updating Merchant Shopify Cart...');

    ShopifyCartService.clearCart().then((response) => {
      console.log('Cleared merchant shopify cart', response);

      ShopifyCartService.addItems(shopifyCartItems).then((response) => {
        console.log('Successfully populated merchant shopify cart with our cart', response);
        setStatus('success');
      })
        .catch(() => setMessage('Shopify has blocked this cart update request. Skipping automatic cart updating...'))
        .finally(() => window.location.href = constants.merchant.shopifyUrl);
    }).catch(() => {
      setMessage('Shopify has blocked this cart update request. Skipping automatic cart updating and redirecting to Shopify store');
      window.location.href = constants.merchant.shopifyUrl;
    });
  };

  const handleCreditTransfer = () => {
    PaymentService.addBeneficiary(constants.merchant.accountNumber, constants.merchant.name)
      .then((response) => {
        console.log('Add Beneficiary:', response);

        PaymentService.postCreditTransfer(
          accountFrom,
          constants.merchant.accountNumber,
          subtotal,
          `${constants.merchant.transactionRefPrefix}-${Math.floor(Date.now() / 1000)}`,
          narrative
        ).then((transferResponse) => {
          console.log('Payment Credit Transfer,', transferResponse);
          PaymentService.removeBeneficiary(constants.merchant.accountNumber)
            .then((response) => {
              console.log('Remove Beneficiary', response);
              setStatus('success');
              setMessage(<p>Transaction ID: <b>{transferResponse.TransactionID}</b><br />Balance: <b>$ {transferResponse.BalanceAfter}</b></p>);
              const difference = parseFloat(transferResponse.BalanceBefore) - parseFloat(transferResponse.BalanceAfter);
              const transactionFee = difference - subtotal;
              console.log('Transaction Fee:', transactionFee);
              checkoutSuccess(`Method: Transfer\nTotal: $${subtotal}\nTxn Fee: $${transactionFee}\nAccount: ${accountFrom}\nBalance: $${transferResponse.BalanceAfter}`);
            });
        }).catch((error) => {
          console.error(error);
          const [globalErrorID, errorText, errorDetails, ..._errorOther] = error.split(';');
          setStatus('error');
          if (typeof constants.api.globalErrors[globalErrorID] === 'undefined') {
            setMessage(errorText || errorDetails);
          } else {
            setMessage(constants.api.globalErrors[globalErrorID]);
          }
        });
      });
  };

  const calculateLoan = () => {
    PaymentService.calculateLoanInstallment(
      '205',
      subtotal,
      numberOfMonths
    ).then((response) => {
      console.log('Calculate', response);
      setMessage(
        <Popconfirm
          title="Continue with Loan?"
          onConfirm={() => handleLoan(response)}
          onCancel={() => history.push('/cart')}
          okText="Yes"
          cancelText="No"
        >
          <ul>
            <li>Interest: {response.Interest}</li>
            <li>Maturity Date: {response.MaturityDate}</li>
            <li><b>Monthly Installment: {response.MonthlyInstallment}</b></li>
            <li>Number of Months: {numberOfMonths}</li>
          </ul>
          <Button href="#">Continue with Loan</Button>
        </Popconfirm>
      );
    });
  };

  const handleLoan = (calculation) => {
    PaymentService.applyForLoan(
      '205',
      constants.merchant.name,
      subtotal,
      numberOfMonths,
      subtotal,
      constants.merchant.name,
      'SGD',
      accountFrom
    ).then((response) => {
      console.log('Loan', response);
      setStatus('success');
      setMessage(`Loan Account ID: ${response.AccountID}`);
      checkoutSuccess(`Method: Loan\nInterest: ${calculation.Interest}\nMatures: ${calculation.MaturityDate}\nMthly Installment: ${calculation.MonthlyInstallment}\nNo of Mths: ${numberOfMonths}`);
    }).catch((error) => {
      console.error(error);
      const [globalErrorID, errorText, errorDetails, ..._errorOther] = error.split(';');
      setStatus('error');
      if (typeof constants.api.globalErrors[globalErrorID] === 'undefined') {
        setMessage(errorText || errorDetails);
      } else {
        setMessage(constants.api.globalErrors[globalErrorID]);
      }
    });
  };

  return (
    <Content style={{ padding: '0 50px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/cart">Cart</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Checkout</Breadcrumb.Item>
      </Breadcrumb>

      {status === 'empty' && <PageHeader
        title="Payment Method"
        subTitle="Choose one option to make payment"
        extra={
          <Button key="1" type="primary" disabled={currentPaymentMethod === 0} onClick={handlePaymentNextStep}>
            {paymentMethodNames[currentPaymentMethod]}
          </Button>
        }
      >
        <Collapse defaultActiveKey={['1']} accordion onChange={handlePaymentChange}>
          <Panel header="Credit Transfer from Deposit Account" key="1">
            <Form onSubmit={() => false}>
              <Form.Item label="Deposit Account">
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder={accountFrom}
                  value={accountFrom}
                  disabled
                />
              </Form.Item>
              <Form.Item label="Remarks">
                <Input
                  prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Remarks"
                  onChange={e => setNarrative(e.target.value)}
                  value={narrative}
                />
              </Form.Item>
            </Form>
            <p>* There will be a flat transaction fee of $0.05 regardless of cart amount.</p>
          </Panel>
          <Panel header={isLoanEnabled ? 'Personal Loan' : `Personal Loan requires >= $${constants.merchant.minimumLoanAmount}`} key="2" disabled={!isLoanEnabled}>
            <p>You will be provided a confirmation screen with the installment amount to be paid monthly.</p>
            <Form onSubmit={() => false}>
              <Form.Item label="Settlement Account">
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder={accountFrom}
                  value={accountFrom}
                  disabled
                />
              </Form.Item>
              <Form.Item label="Repayment Number of Months">
                <Input
                  prefix={<Icon type="number" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Repayment Number of Months"
                  onChange={e => setNumberOfMonths(e.target.value)}
                  value={numberOfMonths}
                />
              </Form.Item>
            </Form>
          </Panel>
          <Panel header="Pay with Credit Card" key="3">
            <p>As this is an <b>exclusive platform</b> for tBank and {constants.merchant.name} customers, we do not accept other forms of payments.</p>
            <p>If you wish to continue purchasing with a Credit Card, you may purchase your items directly from {constants.merchant.name}'s Online Store.</p>
          </Panel>
        </Collapse>
        <br />
        <h2>Cart Contents</h2>
        <Table dataSource={dataSource} columns={columns} />
        <div align="right">
          {
            <strong>Grand Total: ${subtotal} </strong>
          }<br />
          <Button style={{ marginTop: '10px' }} type="danger" onClick={handleBackToCart}>Back to Cart</Button>
        </div>
      </PageHeader>}
      {status !== 'empty' && status !== 'loading' && <Result status={status} title={message} />}
      {status === 'loading' && <h2>{loadingIcon} {message}</h2>}
    </Content>
  );
});

export default Checkout;