/*
    Helper to handle credentials store in localStorage
    Note that the data is stored in PLAINTEXT and is susceptible to local attacks

    Invoke by:
    import Credentials from 'shared/lib/credentials';
    Credentials.set('user', 'password123');
    console.log(Credentials.get());
    > { userID: 'user', PIN: 'password123', OTP: <whatever is set in constants.js> }
    Credentials.clear(); // This will clear all credentials, effectively logging out
*/

import constants from '../constants';

const set = (username, password) => {
    window.localStorage.setItem('username', username);
    window.localStorage.setItem('password', password);
};

const setOTP = (otp) => {
    window.localStorage.setItem('otp', otp);
};

const get = () => (
    {
        userID: window.localStorage.getItem('username'),
        PIN: window.localStorage.getItem('password'),
        OTP: window.localStorage.getItem('otp') || constants.api.defaultOTP
    }
);

const clear = () => (window.localStorage.clear());

const isLoggedIn = () => {
    const data = get();
    return data.userID !== null && data.PIN !== null && data.OTP !== null;
};

const Credentials = {
    get, set, setOTP, clear, isLoggedIn
};

export default Credentials;
