import tbank from '../../shared/lib/request';

// Customer Type has to be either Deposit or Loan
const getDetails = () => tbank(
  'getCustomerDetails',
  {}
).then(response => response.ServiceResponse.CDMCustomer);

const getCustomerAccounts = () => tbank(
  'getCustomerAccounts',
  {}
).then(response => response.ServiceResponse.AccountList.account);

const CustomerService = {
  getDetails, getCustomerAccounts
};

export default CustomerService;