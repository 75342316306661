import shopify from '../../shared/lib/shopify';

// Customer Type has to be either Deposit or Loan
const getCart = () => shopify('cart.js');

const addItems = (items) => shopify('cart/add.js', { 
  method: 'POST',
  data: items
});

const clearCart = () => shopify('cart/clear.js', {
  method: 'POST'
});

const ShopifyCartService = {
  getCart, addItems, clearCart
};

export default ShopifyCartService;