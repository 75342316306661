import React from 'react';
import { Layout, Result, Button } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Credentials from '../shared/lib/credentials';

const { Content } = Layout;

const Home = () => (
  Credentials.isLoggedIn() ? <Redirect to="/products" /> :
    <Content>
      <Result
        status="403"
        title="Welcome to tBank Retail Marketplace!"
        subTitle="Our stores are exclusively for tBank's Most-Valued Customers."
        extra={<Button size="large" type="primary" block><Link to="/login">Login</Link></Button>}
      />
    </Content>
);

export default Home;