import CustomerService from '../../services/tbank/customer';

const set = (customer) => window.localStorage.setItem('customer', JSON.stringify(customer));

const get = () => JSON.parse(window.localStorage.getItem('customer') || '{}');

const clear = () => window.localStorage.removeItem('customer');

const setAccounts = () => {
  CustomerService
    .getCustomerAccounts()
    .then((response) => {
      window.localStorage.setItem('accounts', JSON.stringify(response));
    });
};

const getAccounts = () => {
  return JSON.parse(window.localStorage.getItem('accounts') || '[]');
};

const getDepositAccounts = () => {
  return getAccounts().filter((account) => account.productID[0] === '1');
};

const getLoanAccounts = () => {
  return getAccounts().filter((account) => account.productID[0] !== '1');
};

const Customer = {
  set, get, clear, setAccounts, getAccounts, getDepositAccounts, getLoanAccounts
};

export default Customer;
