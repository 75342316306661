import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Layout, Breadcrumb, PageHeader, Card, Tag, Row, Col, Button, notification } from 'antd';

import Catalog from '../shared/lib/catalog';
import Cart from '../shared/lib/cart';
import Credentials from '../shared/lib/credentials';
import constants from '../shared/constants';

const { Content } = Layout;

const addedToCart = (type, title) => {
  notification[type]({
    message: 'Added to Cart!',
    description: <span>Added <b>{title}</b> to cart!</span>
  });
};

const Product = () => {
  let { handle } = useParams();
  console.log(`Loading product ${handle}`);

  const [product, setProduct] = useState({});
  const loggedIn = Credentials.isLoggedIn();

  useEffect(() => {
    const productIndex = Catalog.findIndexByHandle(handle);
    console.log(`Product is at index ${productIndex}`);
    const product = Catalog.get()[productIndex];
    console.log('Product', product);
    setProduct(product);
  }, [handle]);

  const handleAddToCart = (event) => {
    event.preventDefault();
    Cart.add(handle);
    addedToCart('success', product.title);
  };

  return (
    <Content style={{ padding: '0 50px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/products">Products</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={`/product/${product.handle}`}>{product.title}</Link></Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={16}>
        {product &&
          <PageHeader
            title={product.title}
            style={{
              border: '1px solid rgb(235, 237, 240)',
            }}
            subTitle={product.product_type}
            tags={<Tag color="blue">Vendor: {product.vendor}</Tag>}
            extra={[
              <Button key="2" href={`${constants.merchant.productBaseUrl}${product.handle}`}>View on Shopify</Button>,
              <Button key="1" disabled={!loggedIn} type="primary" onClick={handleAddToCart}>{loggedIn ? 'Add to Cart' : 'Login to Add to Cart'}</Button>,
            ]}
          >
            <Content>
              <Row gutter={24}>
                <Col span={8}>
                  <Card
                    hoverable
                    cover={<img alt={product.title} src={product.images && product.images.length > 0 ? product.images[0].src : constants.merchant.missingImageUrl} />}
                    title={`Price: $${product.variants && product.variants[0].price}`}
                  />
                </Col>
                <Col span={16} dangerouslySetInnerHTML={{ __html: product.body_html }} />
              </Row>
            </Content>
          </PageHeader>
        }
      </Row>
    </Content>
  );
}

export default Product;
