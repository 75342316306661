const constants = {
    api: {
        url: 'https://tbankonline.com/SMUtBank_API/Gateway',
        defaultOTP: '999999',
        timeout: 20000,
        successfulGlobalErrorID: '010000',
        method: 'GET',
        globalErrors: {
            '010000': 'No errors',
            '010010': 'User does not exist',
            '010041': 'OTP has expired'
        }
    },
    merchant: {
        productsUrl: 'https://essentials.banyantree.com/products.json',
        productBaseUrl: 'https://essentials.banyantree.com/products/',
        shopifyUrl: 'https://essentials.banyantree.com',
        name: 'Banyan Tree',
        logoUrl: 'https://cdn.shopify.com/s/files/1/1266/3913/t/11/assets/logo.png',
        accountNumber: '6220',
        missingImageUrl: 'https://cdn.shopify.com/s/files/1/1266/3913/t/11/assets/logo.png',
        minimumLoanAmount: 100
    }
};

export default constants;