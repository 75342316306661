import axios from 'axios';
import constants from '../constants';
import Credentials from './credentials';

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: constants.api.url,
  timeout: constants.api.timeout,
  headers: {
    'Accept': 'application/json'
  },
  method: constants.api.method
});

// Simple function that pulls credentails from the Credential helper if the credentials are not provided
const constructHeaderObject = (serviceName, userID = null, PIN = null, OTP = null) => {
  const { userID: storedUserID, PIN: storedPIN, OTP: storedOTP } = Credentials.get();

  return {
    serviceName: serviceName,
    userID: userID || storedUserID,
    PIN: PIN || storedPIN,
    OTP: OTP || storedOTP
  }
};

/**
 * Request Wrapper with default success/error actions
 */
const request = function (serviceName, content, raw = false, header = null) {
  // If the header object was provided, use that instead
  const headerObject = header || constructHeaderObject(serviceName);

  // Check if the content object was given is nested within the Content key
  const contentObject = typeof content.Content === 'undefined' ? { Content: content } : content;

  // Some requests do not require a content, if so, omit it
  const params = contentObject ? {
    Header: headerObject,
    Content: contentObject
  } : { Header: headerObject };

  const onSuccess = function (response) {
    if (raw) {
      return response.data;
    }
    // The bottom of tbankonline.com/SMUtBank_API_Help/JavaScript16.html
    // Describes the global error IDs
    if (response.data.Content && response.data.Content.ServiceResponse && response.data.Content.ServiceResponse.ServiceRespHeader.GlobalErrorID === constants.api.successfulGlobalErrorID) {
      console.debug('Request Successful!', response);
      return response.data.Content;
    }

    // Deconstruct error from the response header
    const { ErrorText, ErrorDetails, GlobalErrorID } = response.data.Content.ServiceResponse.ServiceRespHeader;
    const formattedErrorText = `${GlobalErrorID};${ErrorText};${ErrorDetails || ''}`;
    console.error(`Request was 200 but Failed: ${formattedErrorText}`);

    // Reject the promise with the given reasons
    return Promise.reject(formattedErrorText);
  }

  const onError = function (error) {
    // Forward on the error if there is just a string
    if (typeof error == 'string') {
      return Promise.reject(error);
    }
    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);

    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  }

  return client({ params })
    .then(onSuccess)
    .catch(onError);
}

export { request as default, constructHeaderObject };
