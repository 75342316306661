import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import Credentials from './shared/lib/credentials';
import CustomerStorage from './shared/lib/customer';
import Customer from './services/tbank/customer';
import Catalog from './shared/lib/catalog';
import constants from './shared/constants';
import ProductsService from './services/banyantree/products';
import Login from './pages/Login';
import Home from './pages/Home';
import Products from './pages/Products';
import Cart from './pages/Cart';
import Product from './pages/Product';
import Checkout from './pages/Checkout';
import './App.css';

const { Header, Footer } = Layout;

const App = () => {
  const [loggedIn, setLoggedIn] = useState(Credentials.isLoggedIn());
  const [user, setUser] = useState(Credentials.get());
  const [name, setName] = useState(loggedIn ? user.userID : 'Not Logged In');

  // Cache customer details
  useEffect(() => {
    if (loggedIn) {
      Customer.getDetails().then((response) => {
        console.log('Customer is logged in; retrieved data:', response);
        setName(`${response.givenName} ${response.familyName}`);
        CustomerStorage.set(response);
        CustomerStorage.setAccounts();
      });
    }
  }, [loggedIn]);

  // Cache product catalog if not already available
  useEffect(() => {
    const catalog = Catalog.get();
    if (!catalog.length) {
      console.log('Downloading and caching product catalog...');
      ProductsService.getProducts().then((response) => {
        console.log(response);
        Catalog.set(response.products);
        console.log(`Product catalog with ${response.products.length} products saved in cache!`);
      });
    } else {
      console.log(`Product catalog already cached; re-using all ${catalog.length} products`);
    }
  }, []);

  const handleLogout = () => {
    CustomerStorage.clear();
    Credentials.clear();
    setLoggedIn(false);
    setUser({});
    setName('Not Logged In');
  };

  const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      loggedIn ?
        <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
  );

  return (
    <Router>
      <Layout className="layout">
        <Header>
          <div className="logo" />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['1']}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="1"><Link to="/">{constants.merchant.name}</Link></Menu.Item>
            <Menu.Item key="3"><Link to="/products">Products</Link></Menu.Item>
            {loggedIn && <Menu.Item key="4"><Link to="/cart">Cart</Link></Menu.Item>}
            <Menu.Item key="5">
              {!loggedIn && <Link to="/login">Login</Link>}
              {loggedIn && <Link to="/logout" onClick={handleLogout}>Logout of {name}</Link>}
            </Menu.Item>
          </Menu>
        </Header>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/logout" exact>
            <Redirect to={{ pathname: '/login' }} />
          </Route>
          <Route path="/products" exact>
            <Products />
          </Route>
          <ProtectedRoute path="/cart" component={Cart} />
          <ProtectedRoute path="/checkout" component={Checkout} />
          <Route path="/product/:handle" children={<Product />} />
        </Switch>
        <Footer style={{ textAlign: 'center' }}>Digital Bankers G1</Footer>
      </Layout>
    </Router>
  );
}

export default App;
