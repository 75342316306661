import React, { useState, useEffect } from 'react';
import { Layout, Breadcrumb, Row, Card, Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import Catalog from '../shared/lib/catalog';
import CartService from '../shared/lib/cart';
import constants from '../shared/constants';

const { Content } = Layout;

const Cart = () => {

  const [dataSource, setDataSource] = useState([]);
  const [updateCart, setUpdateCart] = useState(false);
  const refreshCart = () => setUpdateCart(!updateCart);

  useEffect(() => {
    const productMapping = Catalog.getMapping();
    const catalog = Catalog.get();

    setDataSource(CartService.get().map((handle, index) => {
      const product = catalog[productMapping[handle]];

      return {
        key: index,
        productType: product.product_type,
        product: product.title,
        ImageURL: product.images && product.images.length > 0 ? product.images[0].src : constants.merchant.missingImageUrl,
        quantity: 1,
        price: product.variants[0].price
      }
    }));
  }, [updateCart]);

  const subtotal = dataSource.reduce((total, product) => (total + parseFloat(product.price)), 0);

  const columns = [
    {
      title: 'Product Type',
      dataIndex: 'productType',
      key: 'productType',
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: "",
      dataIndex: "ImageURL",  // this is the value that is parsed from the DB / server side
      render: theImageURL => <img alt={theImageURL} src={theImageURL} height="150" width="150" />
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
  ];

  const handleEmptyCart = (event) => {
    event.preventDefault();
    CartService.clear();
    refreshCart();
  };

  return (
    <Content style={{ padding: '0 50px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Cart</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={16}>
        <Card>
          <Table dataSource={dataSource} columns={columns} />
          <div align="right">
            {
              <strong>Grand Total: $ {subtotal} </strong>
            }<br />
            <Button style={{ marginTop: '10px' }} type="danger" onClick={handleEmptyCart}>Empty Cart</Button>
            <Button style={{ marginTop: '10px' }} type="primary"><Link to="/checkout">Check Out</Link></Button>
          </div>
        </Card>
      </Row>
    </Content>
  );
}

export default Cart;