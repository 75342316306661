import tbank from '../../shared/lib/request';

// Customer Type has to be either Deposit or Loan
const sendEmail = (emailAddress, emailSubject, emailBody) => tbank(
  'sendEmail',
  {emailAddress, emailSubject, emailBody}
).then(response => response.ServiceResponse);

const sendSMS = (mobileNumber, message) => tbank(
  'sendSMS',
  {mobileNumber, message}
).then(response => response.ServiceResponse);

const NotificationService = {
  sendEmail, sendSMS
};

export default NotificationService;