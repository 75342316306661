import tbank from '../../shared/lib/request';

const CreditTransferServiceName = 'creditTransfer';
const BillingOrganizationsServiceName = 'getBillingOrganizations';
const ApplyForLoanServiceName = 'applyForLoan';

const postCreditTransfer = (accountFrom, accountTo, transactionAmount, transactionReferenceNumber, narrative) => tbank(
  CreditTransferServiceName,
  { accountFrom, accountTo, transactionAmount, transactionReferenceNumber, narrative }
).then((response) => ({
  'BalanceAfter': response.ServiceResponse.BalanceAfter._content_,
  'BalanceBefore': response.ServiceResponse.BalanceBefore._content_,
  'TransactionID': response.ServiceResponse.TransactionID._content_
}));

const getBillingOrganizations = () => tbank(
  BillingOrganizationsServiceName,
  {}
);

const applyForLoan = (productID, loanPurpose, loanAmount, numberOfMonths, assetValue, title, currency, settlementAccount) => tbank(
  ApplyForLoanServiceName,
  { productID, loanPurpose, loanAmount, numberOfMonths, assetValue, title, currency, settlementAccount }
).then(response => ({'AccountID': response.ServiceResponse.accountID._content_}));

const calculateLoanInstallment = (productID, principle, numberOfMonths) => tbank(
  'calculateLoanInstallment',
  { productID, principle, numberOfMonths }
).then(response => response.ServiceResponse.InstallmentResponse);

const addBeneficiary = (AccountID, Description) => tbank(
  'addBeneficiary',
  { AccountID, Description },
).then(response => response.ServiceResponse.BeneficiaryDetails);

const getBeneficiaryList = () => tbank(
  'getBeneficiaryList',
  {},
  true
);

const removeBeneficiary = (AccountID) => tbank(
  'removeBeneficiary',
  { AccountID },
).then(() => ({ 'AccountID': AccountID}));

const PaymentService = {
  postCreditTransfer, getBillingOrganizations, applyForLoan, calculateLoanInstallment, addBeneficiary, getBeneficiaryList, removeBeneficiary
};

export default PaymentService;