import { default as tbank, constructHeaderObject } from '../../shared/lib/request';
import Credentials from '../../shared/lib/credentials';

const serviceName = 'loginCustomer';

const login = (userID, PIN) => tbank(
  serviceName,
  {},
  false,
  constructHeaderObject(
    serviceName,
    userID,
    PIN
  )
);

const loginAndSave = (userID, PIN) => login(userID, PIN).then((response) => {
  Credentials.set(userID, PIN);
  return response;
});

const AuthenticationService = {
  login, loginAndSave
};

export default AuthenticationService;